<template>
<div>
  <user-form action="create" ref="childForm" :initial-data="initialData" />
</div>
</template>

<script>
import UserForm from "@/components/users/UserForm.vue";
export default {
  name: "UserCreateForm",
  components: {
    UserForm
  },
  data() {
    return {
      initialData: {
        first_name: null,
        last_name: null,
        login: null,
        password: null,
        note: null,
        account: {
          balance: null,
          expired_at: null,
          is_active: true,
          period: null,
          unlimited: false,
          limit: null
        },
        services_groups: [],
        sources_groups: []
      }
    }
  },
  async mounted() {
    await this.loadUserRoles();
    await this.$refs.childForm.mountAction();
  },
  methods: {
    async loadUserRoles() {
      const data = await this.$store.dispatch('users/getRoles',{});
      // this.initialData.roles = data.data.map((item) => {
      //   return item.name
      // })
    }
  }
}
</script>

<style scoped>

</style>